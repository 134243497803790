<template>
  <v-app style="-webkit-app-region: drag; background: #f0f0f0">
    <!-- <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" expand-on-hover color="#092237" dark app>
      <v-list-item class="px-2 pt-1">
        <v-list-item-avatar tile>
          <v-img src="./assets/logo-mjc.png" alt="admin" @click.stop="mini = !mini" class="clickable" />
        </v-list-item-avatar>

        <v-list-item-title class="mt-2 text-capitalize">
          <h3 style="color: grey">WEB - MJC</h3>
          <div>
            <v-btn color="success" v-if="$store.state.isOnline == true" rounded x-small>ONLINE</v-btn>
            <v-btn color="error" v-else rounded x-small>OFFLINE</v-btn>
          </div>
        </v-list-item-title>
      </v-list-item>
      <v-list shaped class="clickable">
        <template v-for="item in items">
          <v-list-group v-if="item.children" :key="item.text" v-model="item.model" :prepend-icon="item['iconctr']"
            :append-icon="item.model ? item.icon : item['iconalt']" active-class="orange--text">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="(child, i) in item.children" :key="i" route :to="child.route"
              active-class="orange--text" class="ml-5">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else :key="item.text" active-class="orange--text" route :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer> -->

    <v-app-bar app dense elevate-on-scroll style="background: rgb(72,90,227);
background: linear-gradient(158deg, rgba(72,90,227,1) 0%, rgba(29,180,209,1) 100%);" dark>
      <v-app-bar-nav-icon @click.stop="mini = !mini" class="clickable"></v-app-bar-nav-icon>
      <!-- <div class="text-h2">WEB MJC</div> -->
      <v-toolbar-title style="width: 150px" class="ml-0 pl-4">
        <!-- <span class="hidden-sm-and-down">WEB-MJC</span> -->
        <!-- <v-img src="./assets/logo-mjc.png" max-height="20" max-width="20" class="clickable" /> -->
        <span>WEB MJC</span>

      </v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-if="isLoggedIn">
        <v-btn light class="mr-5" text dark>
          <v-icon class="mr-2">mdi-account</v-icon> {{ user.name }}
        </v-btn>
        <v-btn v-on:click="logout()" class="clickable" outlined rounded>
          <v-icon>mdi-logout</v-icon>Logout
        </v-btn>
      </template>

      <template v-else>
        <div class="mr-2">
          <v-btn rounded text>
            <v-icon>mdi-bell</v-icon>
          </v-btn>

        </div>
        <v-btn to="/login" class="clickable rounded-xl" outlined>
          <v-icon class="mr-2">mdi-account</v-icon> LOG IN
        </v-btn>
        <!-- <v-btn to="/login" class="clickable" light ><v-icon right class="mr-2">mdi-account</v-icon> LOG IN</v-btn> -->
        <!-- <v-btn to="/daftar" class="clickable ml-5" outlined rounded>
          <v-icon class="mr-2">mdi-account-plus</v-icon>REGISTER - DAFTAR</v-btn
        > -->
      </template>

    </v-app-bar>
    <!-- <v-btn v-for="(items, index) in  breadcrumbs" :key="index">{{ index }}</v-btn>
    <v-breadcrumbs :items="breadcrumbs" divider=">" class="ml-10 mt-10"></v-breadcrumbs>
     -->

    <v-content>
      <!-- <v-container> -->
      <!-- <v-row> -->
      <router-view></router-view>
      <!-- </v-row> -->
      <!-- </v-container> -->
    </v-content>
    <v-btn v-scroll="onScroll" bottom color="pink" dark fab fixed right small @click="toTop" class="clickable mb-10">
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
// import axios from "axios";

import { mapGetters } from "vuex";
export default {
  name: "App",
  props: {
    sorce: String,
  },
  mounted() {
    // axios.get(this.$store.state.apiServer + "mounted");
  },
  data: () => ({
    drawer: null,
    mini: false,
    tab: false,
  }),
  computed: {
    getApiServer() {
      return this.$store.state.apiServer;
    },
    getApiLokal() {
      return this.$store.state.apiLokal;
    },
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      user: "user",
      items: "menu",
    }),
    breadcrumbs() {
      console.log(this.$store.state.breadcrumbs);
      return this.$store.state.breadcrumbs
    },
  },
  methods: {

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>

<style>
/* .v-data-table tbody td {
    font-size: 0.750rem !important;
}
.v-chip {
  font-size: 0.750rem !important;
}
.v-btn {
    font-size: 0.750rem !important;
    text-transform: capitalize !important;
} */
@font-face {
  font-family: "OpenSans";
  src: url(./assets/OpenSans.ttf) format("truetype");
}

.v-application {
  font-family: "OpenSans" !important;
}

html {
  font-size: 14px !important;
}

/* table th + th { border-left:1px solid #dddddd; }
table td + td { border-left:1px solid #dddddd; } */

.clickable {
  -webkit-app-region: no-drag;
}

::-webkit-scrollbar {
  /* width: 12px; */
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  /* border-radius: 10px; */
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  /* border-radius: 10px; */
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
  width: 0;
  background: transparent;
}

.container {
  max-width: 100% !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 18px !important;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.v-dialog>.v-card>.v-card__text {
  padding: 0 24px 0px !important;
}

.v-list-item {
  min-height: 30px !important;
}

.v-list-item__action {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.v-list-item__title {
  font-size: 0.9rem !important;
}
</style>
