<template>
    <v-container>
        <v-row>
            <v-col>
                <h2>KONFIRMASI APPROVAL BARANG, JASA, PEMBAYARAN</h2>
                <p>Manajemenen approval transaksi pengadaan barang/jasa dan pembayaran</p>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-tabs class="mt-4 rounded-xl rounded-b-0" v-model="tabs" color="primary" grow>
            <v-tab>
                <v-icon class="mr-4">mdi-alert-octagon-outline</v-icon>
                <strong>Belum Konfirmasi</strong>
            </v-tab>
            <v-tab>
                <v-icon class="mr-4">mdi-checkbox-marked-outline</v-icon><strong>Sudah Konfirmasi</strong>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs">
            <v-tab-item>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="auto" lg="3">
                                        <v-text-field label="Pencarian" dense outlined clearable
                                            hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn color="success" rounded outlined><v-icon>mdi-refresh</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-text>
                                <v-card outlined>
                                    <v-data-table :headers="belum.headers" :items="belum.items" :loading="belum.loading"
                                        :search="belum.search">

                                    </v-data-table>
                                </v-card>
                            </v-card-text>
                        </v-card>

                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>

        <notifications position="bottom right"></notifications>
    </v-container>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            tabs: 0,
            belum: {
                headers: [
                    { text: "No. Transaksi", value: "" },
                    { text: "Tanggal", value: "" },
                    { text: "Jenis", value: "" },
                    { text: "Sub Jenis", value: "" },
                    { text: "Mata Uang", value: "" },
                    { text: "Total Nilai", value: "" },
                    { text: "Opsi", value: "" },
                ],
                items: [],
                loading: false,
                search: ""
            }
        }
    },
    mounted() {
        this.loadBelum()
    },
    methods: {
        loadBelum() {
            this.belum.loading = true
            this.belum.items = []
            axios.post('approval/tiering', {
                alias : this.$store.state.auth.user.name,
            })
            .then((res) => {
                if (res.data.success == true) {
                    this.$notify({ type: "error", text: res.data.message})
                    console.log(res.data.error);
                    this.belum.loading = false
                    return
                }
                this.belum.items = res.data.result
                this.belum.loading = false
            })  
            .catch((err) => {
                this.belum.loading = false
                console.log(err);
            })

        },
    }
}
</script>

<style>

</style>