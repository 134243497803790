<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title primary-title> Daftar User Baru </v-card-title>
          <v-card-text>
            <v-form v-model="isValid">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.nik"
                    :rules="rules.nik"
                    label="NIK"
                    id="id"
                    outlined
                    v-on:keyup.enter="loadDataKaryawan()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn block color="primary" @click="loadDataKaryawan()" x-large>
                    Cek
                  </v-btn>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    label="Nama Lengkap"
                    v-model="form.nama_lengkap"
                    :rules="rules.nama_lengkap"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Bagian/Departemen"
                    v-model="form.division"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Level"
                    v-model="form.level"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" v-if="showForm">
                  <v-text-field
                    label="Username"
                    v-model="form.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" v-if="showForm">
                  <v-text-field
                    label="Password"
                    v-model="form.password"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" v-if="showForm">
                  <v-text-field
                    label="Konfirm Password"
                    v-model="form.konfirmPassword"
                    :rules="rules.konfirmPassword"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              block
              color="success"
              @click="simpanDaftar"
              :disabled="!isValid"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">b</v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      showForm: false,
      isValid: false,
      showPassword: false,
      form: {
        nik: "",
        name: "",
        nama_lengkap: "",
        email: "",
        level: "user",
        kode_bag: "",
        division: "",
        password: "",
        konfirmPassword: "",
      },
      rules: {
        nik: [
          (v) => v != "" || "NIK harus diisi",
          (v) => v.length <= 5 || "NIK hanya 5 karakter",
          (v) => v.length >= 5 || "NIK hanya 5 karakter",
        ],
        nama_lengkap: [(v) => v != "" || "Nama Lengkap harus diisi"],
        konfirmPassword: [
          (v) => v != "" || "Konfirm Password harus diisi",
          (v) => v == this.form.password || "Password tidak sama",
        ],
      },
    };
  },
  methods: {
    loadDataKaryawan() {
      axios
        .get(this.getApiServer + "auth/" + this.form.nik + '/createuser')
        .then((response) => {
          console.log(response);
          if (response.data.message == "exist") {
            alert("NIK sudah terdaftar");
            this.showForm = false;
            this.form.nama_lengkap = "";
            this.form.division = "";
            return;
          }
          if (response.data.message == 'notfound') {
            alert("NIK " + this.form.nik + " tidak ditemukan");
            this.showForm = false;
            this.form.nama_lengkap = "";
            this.form.division = "";
            return;
          }
          this.showForm = true;
          console.log(response.data.data);
          this.form.nama_lengkap = response.data.data[0].nama;
          this.form.kode_bag = response.data.data[0].kode_bagian;
          this.form.division = response.data.data[0].nama_bagian;
        })
        .catch((err) => {
          alert('Ada kesalahan, hub IT (240)' + err)
          console.log(err);
        });
    },
    simpanDaftar() {
      console.log(this.form);
        axios
        .post(this.getApiServer + 'auth/createuser',this.form)
        .then((res) => {
          if (res.data.success == true ) {
            alert(res.data.message)
            console.log("input berhasil");
            console.log(res.data);
            this.$router.push({ name: "Login" });
          } else {
            alert(res.data.message + ' | ' + res.data.data)
            console.log(res.data.data);
          }
        })
        .catch((err) => {
          alert('Input user gagal')
            console.log("input gagal");
            console.log(err);
        });
    },
  },
  computed: {
    getApiServer() {
      return this.$store.state.apiServer;
    },
    getApiLokal() {
      return this.$store.state.apiLokal;
    },
  },
};
</script>

<style>
</style>