import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Daftar from '../views/Daftar.vue'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Route from '../views/Route.vue'

import KonfirmasiBarangJasa from '../views/Dirop/KonfirmasiBarangJasa'

import TieringViewKonfirmasi from '../views/Tiering/ViewTiering.vue'
// import axios from 'axios'
// import axios from 'axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/route',
    name: 'Route',
    component: Route,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    // meta: {
    //   auth: true
    // }
  },
  {
    path: '/daftar',
    name: 'Daftar',
    component: Daftar,
    // meta: {
    //   auth: true
    // }
  },
  {
    path: '/konfirmasibarangjasa',
    name: 'KonfirmasiBarangJasa',
    component: KonfirmasiBarangJasa,
    meta: {
      auth: true
    }
  },
  {
    path: '/tiering/viewkonfirmasi',
    name: 'TieringViewKonfirmasi',
    component: TieringViewKonfirmasi,
    meta: {
      auth: true
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  // base: 'process.env.BASE_URL',
  base: 'mjc-manajemen',
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to);
  // console.log(from);
  // axios.post("http://192.168.2.8:8000/api/recordtrackmenu", {
  //   'menu_from': from.fullPath,
  //   'menu_to': to.fullPath,
  // })
  //   .then((res) => {
  //     if (res.data.success == false) {
  //       // console.log(res.data.data);
  //       store.commit('setOffline')
  //       return
  //     }
  //     store.commit('setOnline')
  //     // console.log(res.data.data);

  //   })
  //   .catch((err) => {
  //     store.commit('setOffline')
  //     console.log(err);
  //   })

  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters.isLoggedIn && store.getters.user) {
      // console.log(store.getters.isLoggedIn);
      // console.log('cekrouter');
      next()
      return
    }
    next('/login')
  }

  if (to.matched.some(record => record.meta.guest)) {
    if (!store.getters.isLoggedIn) {
      next('/login')
      return
    }
  }
  next()


})

export default router
