<template>
    <v-container>
        <v-row>
            <v-col>
                <h2>Konfirmasi ACC/TIDAK ACC Barang Dan Jasa</h2>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-tabs class="mt-4 rounded-xl rounded-b-0" v-model="tabs" color="primary" grow>
            <v-tab>
                <v-icon class="mr-4">mdi-alert-octagon-outline</v-icon>
                <strong>Belum Konfirmasi</strong>
            </v-tab>
            <v-tab>
                <v-icon class="mr-4">mdi-checkbox-marked-outline</v-icon><strong>Sudah Di-ACC</strong>
            </v-tab>

        </v-tabs>

        <!-- DAFTAR BELUM ACC -->
        <v-tabs-items v-model="tabs">
            <v-tab-item>
                <v-row>
                    <v-col>
                        <v-card elevation="0">
                            <v-card-text>
                                <v-row>
                                    <v-col cols="auto" lg="3">
                                        <v-text-field label="Pencarian" v-model="belumAcc.search" hide-details dense
                                            clearable outlined>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn @click="loadData()">
                                            <v-icon>mdi-refresh</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card elevation="0">
                            <v-card-text class="py-2">
                                <v-row>
                                    <v-col cols="auto" lg="2">
                                        <v-btn-toggle class="rounded-xl" v-model="filter" color="white">
                                            <!-- <v-col cols="auto" lg="2" class="ma-o pa-0"> -->
                                            <v-btn @click="filterBelumAcc(0)" block outlined color="info">
                                                <v-icon></v-icon>
                                                <!-- <v-icon class="mr-3" v-if="filter == 0" color="primary">
                                                    mdi-chevron-right</v-icon> -->
                                                USULAN/PO <v-chip class="ml-2" color="info" x-small>{{
                                                        this.belumAcc.lengthItemsPo
                                                }}</v-chip>
                                            </v-btn>
                                            <!-- </v-col> -->
                                            <!-- <v-col cols="auto" lg="2"  class="ma-o pa-0"> -->
                                            <v-btn @click="filterBelumAcc(1)" block outlined color="info">
                                                <!-- <v-icon class="mr-3" v-if="filter == 1" color="primary">
                                                    mdi-chevron-right</v-icon> -->
                                                PEMBAYARAN <v-chip class="ml-2" color="info" x-small>{{
                                                        this.belumAcc.lengthItemsBayar
                                                }}</v-chip>
                                            </v-btn>

                                            <!-- </v-col> -->
                                            <!-- <v-col cols="auto" lg="2"  class="ma-o pa-0" tile> -->
                                            <v-btn @click="filterBelumAcc(2)" block outlined color="info">
                                                <!-- <v-icon class="mr-3" v-if="filter == 2" color="primary">
                                                    mdi-chevron-right</v-icon> -->
                                                SEMUA <v-chip class="ml-2" color="info" x-small>{{ belumAcc.items.length
                                                }}</v-chip>
                                            </v-btn>

                                            <!-- </v-col> -->
                                        </v-btn-toggle>
                                    </v-col>
                                    <!-- <v-col cols="auto" lg="2">
                                        <v-btn outlined block color="grey" @click="filterBelumAcc('PO')">Usulan dan PO
                                            Barang/Jasa ({{ this.belumAcc.lengthItemsPo }})</v-btn>
                                    </v-col>
                                    <v-col cols="auto" lg="2">
                                        <v-btn outlined block color="grey" @click="filterBelumAcc('BAYAR')">Usulan
                                            Pembayaran ({{ this.belumAcc.lengthItemsBayar }})</v-btn>
                                    </v-col>
                                    <v-col cols="auto" lg="2">
                                        <v-btn outlined block color="grey" @click="filterBelumAcc('SEMUA')">Semua ({{ belumAcc.items.length}})
                                        </v-btn>
                                    </v-col> -->
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card outlined class="mx-4 mt-4">

                            <v-card-text class="pa-0 ma-0">

                                <v-data-table :headers="belumAcc.headers" :items="belumAcc.filterItems"
                                    :loading="belumAcc.loading" :search="belumAcc.search">
                                    <template v-slot:[`item.opsi`]="{ item }">
                                        <v-btn color="info" @click="showDetail(item.no_po, item.jenis)" small text>
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.Tanggal`]="{ item }">
                                        {{ getFormatTanggal(item.Tanggal) }}
                                    </template>
                                    <template v-slot:[`item.nilai`]="{ item }">
                                        {{ getFormatNumber(item.nilai) }}
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-dialog v-model="dialogDetailNonRekap" :overlay="false" max-width="1000px"
                    transition="dialog-transition">
                    <v-card height="auto" min-height="500" class="ma-0">
                        <v-card-title class="py-2">
                            No. Usulan/No. PO : {{ noPo }}
                            <v-spacer></v-spacer>
                            <v-btn color="error" @click="dialogDetailNonRekap = false" outlined text>
                                <v-icon x-large>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                            <v-row class="my-2">
                                <v-col cols="auto" lg="8" sm="12">
                                    <table v-if="!header.loading">
                                        <tr>
                                            <td width="30%">Perusahaan</td>
                                            <td width="5%">:</td>
                                            <td><strong>{{ header.items.perusahaan }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Supplier</td>
                                            <td>:</td>
                                            <td><strong>{{ header.items.nama }}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Grand Total</td>
                                            <td>:</td>
                                            <td><strong>{{ header.items.mata_uang }} {{
                                                    getFormatNumber(header.items.total)
                                            }}</strong></td>
                                        </tr>
                                    </table>
                                </v-col>
                                <v-col cols="auto" lg="4" sm="12" class="d-flex justify-end"
                                    v-if="kategori == 'belum_acc'">
                                    <v-btn color="success" class="mr-4" width="200" @click="updateKonfirmasi('ACC')"
                                        :loading="konfirmasi.loading">
                                        <v-icon>mdi-check</v-icon> ACC
                                    </v-btn>
                                    <v-btn color="warning" width="200" @click="updateKonfirmasi('TIDAK ACC')"
                                        :loading="konfirmasi.loading">
                                        <v-icon>mdi-close</v-icon>TIDAK ACC
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto" lg="6">
                                    <v-text-field label="Pencarian" v-model="detailNonRekap1.search" hide-details dense
                                        clearable outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn @click="loadHeaderNonRekap(), loadDetailNonRekap1()">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-card outlined>
                                        <v-card-text class="pa-0 ma-0">
                                            <v-data-table dense :headers="detailNonRekap1.headers"
                                                :items="detailNonRekap1.items" :loading="detailNonRekap1.loading"
                                                :search="detailNonRekap1.search">
                                                <template v-slot:[`item.jumlah`]="{ item }">
                                                    {{ getFormatNumber(item.jumlah) }}
                                                </template>
                                                <template v-slot:[`item.harga`]="{ item }">
                                                    {{ getFormatNumber(item.harga) }}
                                                </template>
                                                <template v-slot:[`item.total`]="{ item }">
                                                    {{ getFormatNumber(item.total) }}
                                                </template>
                                            </v-data-table>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialogDetailRekap" :overlay="false" max-width="1000px"
                    transition="dialog-transition">

                    <v-card>
                        <v-card-title>
                            No. Usulan/No. PO : {{ noPo }}
                            <v-spacer></v-spacer>
                            <v-btn color="error" @click="dialogDetailRekap = false" outlined text>
                                <v-icon x-large>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text v-if="kategori == 'belum_acc'">
                            <v-row class="my-2">
                                <v-col cols="8">
                                    <table v-if="!headerRekap.loading">
                                        <tr>
                                            <td width="30%">Perusahaan</td>
                                            <td width="5%">:</td>
                                            <td><strong>{{ headerRekap.items.perusahaan
                                            }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Tanggal</td>
                                            <td>:</td>
                                            <td><strong>{{ headerRekap.items.Tanggal
                                            }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Jenis</td>
                                            <td>:</td>
                                            <td><strong>{{ headerRekap.items.jenis
                                            }}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Sub Jenis</td>
                                            <td>:</td>
                                            <td><strong>{{ headerRekap.items.jenis2
                                            }}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Grand Total</td>
                                            <td>:</td>
                                            <td><strong>{{
                                                    getFormatNumber(headerRekap.items.nilai)
                                            }}</strong></td>
                                        </tr>
                                    </table>
                                </v-col>
                                <v-col cols="4" class="d-flex justify-end" v-if="kategori == 'belum_acc'">
                                    <v-btn color="success" class="mr-4" width="200" @click="updateKonfirmasi('ACC')"
                                        :loading="konfirmasi.loading">
                                        <v-icon>mdi-check</v-icon> ACC
                                    </v-btn>
                                    <v-btn color="warning" width="200" @click="updateKonfirmasi('TIDAK ACC')"
                                        :loading="konfirmasi.loading">
                                        <v-icon>mdi-close</v-icon>TIDAK ACC
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto" lg="6">
                                    <v-text-field label="Pencarian" v-model="detailRekap1.search" hide-details dense
                                        clearable outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn @click="loadHeaderRekap(), loadDetailRekap1()">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto" lg="12" class="px-1">
                                    <v-card outlined>
                                        <v-card-text class="pa-0 ma-0">
                                            <v-data-table :headers="detailRekap1.headers" :items="detailRekap1.items"
                                                :loading="detailRekap1.loading" :search="detailRekap1.search" dense>
                                                <template v-slot:[`item.jumlah`]="{ item }">
                                                    {{ getFormatNumber(item.jumlah) }}
                                                </template>
                                                <template v-slot:[`item.opsi`]="{ item, index }">
                                                    <v-btn small color="info" text
                                                        @click="showDetailRekap2(item.nama, index)">
                                                        <v-icon small>mdi-eye</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-data-table>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <!-- DIALOG REKAP DETAIL 2 -->
                            <v-dialog v-model="dialogDetailRekap2" :overlay="false" max-width="1300px"
                                transition="dialog-transition">
                                <v-card min-height="500px">
                                    <v-card-title>
                                        No. Usulan/No. PO : {{ noPo }}
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="dialogDetailRekap2 = false" outlined text>
                                            <v-icon x-large>mdi-close</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row class="mt-2">
                                            <v-col>
                                                <table>
                                                    <tr>
                                                        <td width="40%">Nama Supplier</td>
                                                        <td width="5%">:</td>
                                                        <td>{{ itemsDetailRekap2.nama }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mata Uang</td>
                                                        <td>:</td>
                                                        <td>{{ itemsDetailRekap2.mata_uang }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jumlah Nilai</td>
                                                        <td>:</td>
                                                        <td>{{ getFormatNumber(itemsDetailRekap2.jumlah)
                                                        }}</td>
                                                    </tr>
                                                </table>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-row class="mt-0">
                                            <v-col cols="auto" lg="4" sm="12" class="px-1">
                                                <v-card outlined>
                                                    <v-card-text class="pa-0 ma-0">
                                                        <v-data-table dense :headers="detailRekap2.headers"
                                                            :items="detailRekap2.items" :loading="detailRekap2.loading"
                                                            :search="detailRekap2.search">
                                                            <template v-slot:[`item.n_bayar`]="{ item }">
                                                                {{ getFormatNumber(item.n_bayar) }}
                                                            </template>
                                                            <template v-slot:[`item.opsi`]="{ item }">
                                                                <v-btn small color="info" text
                                                                    @click="showDetailRekap3(item.nama)">
                                                                    <v-icon small>mdi-eye</v-icon>
                                                                </v-btn>
                                                            </template>
                                                        </v-data-table>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="auto" lg="8" class="px-1">
                                                <v-card outlined>
                                                    <v-card-text class="pa-0 ma-0">
                                                        <v-data-table dense :headers="detailRekap3.headers"
                                                            :items="detailRekap3.items" :loading="detailRekap3.loading"
                                                            :search="detailRekap3.search">
                                                            <template v-slot:[`item.jumlah`]="{ item }">
                                                                {{ getFormatNumber(item.jumlah) }}
                                                            </template>
                                                            <template v-slot:[`item.harga`]="{ item }">
                                                                {{ getFormatNumber(item.harga) }}
                                                            </template>
                                                            <template v-slot:[`item.total`]="{ item }">
                                                                {{ getFormatNumber(item.total) }}
                                                            </template>
                                                        </v-data-table>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                            <!-- END DIALOG REKAP DETAIL 2 -->
                        </v-card-text>
                        <v-card-text v-else>
                            <v-row class="my-2">
                                <v-col cols="8">
                                    <table v-if="!headerRekap.loading">
                                        <tr>
                                            <td width="30%">Perusahaan</td>
                                            <td width="5%">:</td>
                                            <td><strong>{{ headerRekap.items.perusahaan
                                            }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Tanggal</td>
                                            <td>:</td>
                                            <td><strong>{{ headerRekap.items.Tanggal
                                            }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Jenis</td>
                                            <td>:</td>
                                            <td><strong>{{ headerRekap.items.jenis
                                            }}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Sub Jenis</td>
                                            <td>:</td>
                                            <td><strong>{{ headerRekap.items.jenis2
                                            }}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Grand Total</td>
                                            <td>:</td>
                                            <td><strong>{{
                                                    getFormatNumber(headerRekap.items.nilai)
                                            }}</strong></td>
                                        </tr>
                                    </table>
                                </v-col>
                                <v-col cols="4" class="d-flex justify-end" v-if="kategori == 'belum_acc'">
                                    <v-btn color="success" class="mr-4" width="200" @click="updateKonfirmasi('ACC')"
                                        :loading="konfirmasi.loading">
                                        <v-icon>mdi-check</v-icon> ACC
                                    </v-btn>
                                    <v-btn color="warning" width="200" @click="updateKonfirmasi('TIDAK ACC')"
                                        :loading="konfirmasi.loading">
                                        <v-icon>mdi-close</v-icon>TIDAK ACC
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto" lg="6">
                                    <v-text-field label="Pencarian" v-model="detailRekap1.search" hide-details dense
                                        clearable outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn @click="loadHeaderRekapSudah(), loadDetailRekap1()">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto" lg="12" class="px-1">
                                    <v-card outlined>
                                        <v-card-text class="pa-0 ma-0">
                                            <v-data-table :headers="detailRekap1.headers" :items="detailRekap1.items"
                                                :loading="detailRekap1.loading" :search="detailRekap1.search">
                                                <template v-slot:[`item.jumlah`]="{ item }">
                                                    {{ getFormatNumber(item.jumlah) }}
                                                </template>
                                                <template v-slot:[`item.opsi`]="{ item, index }">
                                                    <v-btn small color="info" text
                                                        @click="showDetailRekap2(item.nama, index)">
                                                        <v-icon small>mdi-eye</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-data-table>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <!-- DIALOG REKAP DETAIL 2 -->
                            <v-dialog v-model="dialogDetailRekap2" :overlay="false" max-width="1300px"
                                transition="dialog-transition">
                                <v-card min-height="500px">
                                    <v-card-title>
                                        No. Usulan/No. PO : {{ noPo }}
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="dialogDetailRekap2 = false" outlined text>
                                            <v-icon x-large>mdi-close</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row class="mt-2">
                                            <v-col>
                                                <table>
                                                    <tr>
                                                        <td width="40%">Nama Supplier</td>
                                                        <td width="5%">:</td>
                                                        <td>{{ itemsDetailRekap2.nama }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mata Uang</td>
                                                        <td>:</td>
                                                        <td>{{ itemsDetailRekap2.mata_uang }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jumlah Nilai</td>
                                                        <td>:</td>
                                                        <td>{{ getFormatNumber(itemsDetailRekap2.jumlah)
                                                        }}</td>
                                                    </tr>
                                                </table>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-row class="mt-0">
                                            <v-col cols="auto" lg="4" sm="12" class="px-1">
                                                <v-card outlined>
                                                    <v-card-text class="pa-0 ma-0">
                                                        <v-data-table dense :headers="detailRekap2.headers"
                                                            :items="detailRekap2.items" :loading="detailRekap2.loading"
                                                            :search="detailRekap2.search">
                                                            <template v-slot:[`item.n_bayar`]="{ item }">
                                                                {{ getFormatNumber(item.n_bayar) }}
                                                            </template>
                                                            <template v-slot:[`item.opsi`]="{ item }">
                                                                <v-btn small color="info" text
                                                                    @click="showDetailRekap3(item.nama)">
                                                                    <v-icon small>mdi-eye</v-icon>
                                                                </v-btn>
                                                            </template>
                                                        </v-data-table>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="auto" lg="8" class="px-1">
                                                <v-card outlined>
                                                    <v-card-text class="pa-0 ma-0">
                                                        <v-data-table dense :headers="detailRekap3.headers"
                                                            :items="detailRekap3.items" :loading="detailRekap3.loading"
                                                            :search="detailRekap3.search">
                                                            <template v-slot:[`item.jumlah`]="{ item }">
                                                                {{ getFormatNumber(item.jumlah) }}
                                                            </template>
                                                            <template v-slot:[`item.harga`]="{ item }">
                                                                {{ getFormatNumber(item.harga) }}
                                                            </template>
                                                            <template v-slot:[`item.total`]="{ item }">
                                                                {{ getFormatNumber(item.total) }}
                                                            </template>
                                                        </v-data-table>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-card-text>
                    </v-card>

                </v-dialog>
            </v-tab-item>
            <!-- END DAFTAR BELUM ACC -->

            <!-- DAFTAR SUDAH ACC -->
            <v-tab-item>
                <v-row>
                    <v-col>
                        <v-card elevation="0">
                            <v-card-text>
                                <v-row>
                                    <v-col cols="auto" lg="3">
                                        <v-text-field label="Pencarian" v-model="sudahAcc.search" hide-details dense
                                            clearable outlined>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn @click="loadDataAcc()">
                                            <v-icon>mdi-refresh</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card elevation="0" outlined class="mx-4">
                            <v-card-text class="pa-0 ma-0">
                                <v-data-table :headers="sudahAcc.headers" :items="sudahAcc.items"
                                    :loading="sudahAcc.loading" :search="sudahAcc.search">
                                    <template v-slot:[`item.tanggal`]="{ item }">
                                        {{ getFormatTanggal(item.tanggal) }}
                                    </template>
                                    <template v-slot:[`item.tgl_acc`]="{ item }">
                                        {{ getFormatTanggal(item.tgl_acc) }}
                                    </template>
                                    <template v-slot:[`item.total`]="{ item }">
                                        {{ getFormatNumber(item.total) }}
                                    </template>
                                    <template v-slot:[`item.opsi`]="{ item }">
                                        <v-btn color="info" @click="showDetailSudahAcc(item.no_po, item.jenis)" text>
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
            <!-- END DAFTAR SUDAH ACC -->
        </v-tabs-items>

        <!-- <v-row>
            <v-col>
                <v-btn @click="cekSwal()">Test</v-btn>
            </v-col>
        </v-row> -->

        <notifications position="bottom right"></notifications>

    </v-container>
</template>

<script>
import axios from 'axios'
import numeral from "numeral";
import Vue from 'vue';
import moment from 'moment';
export default {
    data() {
        return {
            tabs: 0,
            jenisKonfirmasi: 'center',
            filter: 2,
            belumAcc: {
                headers: [
                    { text: "No. PO", value: "no_po", width: "10%" },
                    { text: "Tanggal", value: "Tanggal", width: "10%" },
                    { text: "Jenis", value: "jenis", width: "10%" },
                    { text: "Sub Jenis", value: "jenis2", width: "10%" },
                    { text: "Mata Uang", value: "mata_uang", divider: true, width: "10%" },
                    { text: "Total Nilai", value: "nilai", divider: true, align: "right", width: "10%" },
                    { text: "Opsi", value: "opsi", align: "center", width: "10%" },
                ],
                items: [],
                lengthItemsPo: 0,
                lengthItemsBayar: 0,
                filterItems: [],
                loading: false,
                search: "",
            },
            sudahAcc: {
                headers: [
                    { text: "No. PO", value: "no_po" },
                    { text: "Jenis", value: "jenis" },
                    { text: "Tanggal Permintaan", value: "tanggal" },
                    { text: "Tanggal ACC", value: "tgl_acc" },
                    { text: "Mata Uang", value: "mata_uang", divider: true },
                    { text: "Total", value: "total", align: "right", divider: true },
                    { text: "Opsi", value: "opsi", align: "center" },
                ],
                items: [],
                loading: false,
                search: "",
            },
            header: {
                headers: [
                    { text: "No. PO", value: "no_po" },
                    { text: "Tanggal", value: "Tanggal" },
                    { text: "Jenis", value: "jenis" },
                    { text: "Sub Jenis", value: "jenis2" },
                    { text: "Mata Uang", value: "mata_uang", divider: true },
                    { text: "Total Nilai", value: "nilai", divider: true, align: "right" },
                    { text: "Opsi", value: "opsi", align: "center" },
                ],
                items: [],
                loading: false,
                search: "",
            },

            detailNonRekap1: {
                headers: [
                    { text: "Pekerjaan", value: "pekerjaan" },
                    { text: "Keterangan", value: "keterangan" },
                    { text: "Jumlah", value: "jumlah", align: "right" },
                    { text: "Harga", value: "harga", align: "right", divider: true, },
                    { text: "Total", value: "total", divider: true, align: "right" },
                ],
                items: [],
                loading: false,
                search: "",
            },

            headerRekap: {
                items: [],
                loading: false,
                search: "",
            },
            detailRekap1: {
                headers: [
                    { text: "Nama", value: "nama" },
                    { text: "Mata Uang", value: "mata_uang", divider: true },
                    { text: "Total", value: "jumlah", align: "right", divider: true },
                    { text: "Opsi", value: "opsi", width: "5%", align: "center" },
                ],
                items: [],
                loading: false,
                search: "",
            },
            detailRekap2: {
                headers: [
                    { text: "No. PO", value: "no_po", divider: true },
                    { text: "Total", value: "n_bayar", align: "right" },
                ],
                items: [],
                loading: false,
                search: "",
            },
            detailRekap3: {
                headers: [
                    { text: "No. PO", value: "no_po" },
                    { text: "Nama Barang", value: "nama_barang" },
                    { text: "Keterangan", value: "keterangan" },
                    { text: "Jumlah", value: "jumlah", align: "right" },
                    { text: "Harga", value: "harga", align: "right", divider: true },
                    { text: "Total", value: "total", align: "right" },
                ],
                items: [],
                loading: false,
                search: "",
            },
            konfirmasi: {
                loading: false,
            },
            dialogDetailNonRekap: false,
            dialogDetailRekap: false,
            dialogDetailRekap2: false,
            noPo: "",
            jenis: "",
            nama: "",
            kategori: "",
            indexDetail2: 0,
            itemsDetailRekap2: {
                nama: "",
                mata_uang: "",
                jumlah: ""
            }
        }
    },
    mounted() {
        this.loadData()
        this.loadDataAcc()
        // setTimeout(() => {
        //     this.filterBelumAcc('SEMUA')
        // }, 2000);

    },
    methods: {
        filterBelumAcc(filter) {
            console.log(filter);
            if (filter == 0) {
                this.belumAcc.filterItems = this.belumAcc.items.filter(function (el) {
                    return el.jenis.substring(0, 12) != 'USULAN BAYAR'
                })

            } else if (filter == 1) {
                this.belumAcc.filterItems = this.belumAcc.items.filter(function (el) {
                    return el.jenis.match(/USULAN BAYAR.*/)
                })
            } else if (filter == 2) {

                this.belumAcc.filterItems = this.belumAcc.items
                console.log(this.belumAcc.items);
            }
            this.belumAcc.lengthItemsPo = this.belumAcc.items.filter(function (el) {
                return el.jenis.substring(0, 12) != 'USULAN BAYAR'
            }).length
            this.belumAcc.lengthItemsBayar = this.belumAcc.items.filter(function (el) {
                return el.jenis.match(/USULAN BAYAR.*/)
            }).length

        },
        async updateKonfirmasi(sKonfirmasi) {
            console.log(sKonfirmasi);
            this.konfirmasi.loading = true
            axios.post('/konfirmasi/update', {
                'no_po': this.noPo,
                'jenis': this.jenis,
                'konfirmasi': sKonfirmasi
            })
                .then((res) => {
                    if (res.data.success == false) {
                        this.konfirmasi.loading = false
                        Vue.swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            footer: res.data.err
                        })
                        return
                    }
                    this.konfirmasi.loading = false
                    Vue.swal.fire(
                        'Sukses',
                        'Update Konfirmasi ' + this.noPo + ' : ' + sKonfirmasi + ' Berhasil',
                        'success'
                    )
                    this.dialogDetailRekap = false
                    this.dialogDetailNonRekap = false
                    this.loadData()
                    this.loadDataAcc()
                    this.filterBelumAcc(this.filter)
                    this.noPo = ""
                    this.jenis = ""
                    this.nama = ""
                })
                .catch((err) => {
                    console.log(err);
                    this.konfirmasi.loading = false
                    Vue.swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: err
                    })
                })
        },
        async loadData() {
            this.belumAcc.loading = true
            this.belumAcc.items = []
            await axios.get("/konfirmasi/belum")
                .then((res) => {
                    if (res.data.success == false) {
                        this.belumAcc.loading = false
                        this.belumAcc.items = []
                        console.log(res.data.data);
                        return
                    }

                    this.$notify({ type: "success", text: res.data.message })
                    this.belumAcc.loading = false
                    this.belumAcc.items = res.data.data
                    this.filterBelumAcc(2)
                })
                .catch((err) => {
                    this.belumAcc.loading = false
                    this.belumAcc.items = []
                    console.log(err);
                })
        },
        async loadDataAcc() {
            this.sudahAcc.loading = true
            this.sudahAcc.items = []
            await axios.get("/konfirmasi/sudah", {
                no_po: this.noPo,
                jenis: this.jenis,
                nama: this.nama
            })
                .then((res) => {
                    if (res.data.success == false) {
                        this.sudahAcc.loading = false
                        this.sudahAcc.items = []
                        console.log(res.data.data);
                        return
                    }

                    // this.$notify({ type: "success", text: res.data.message })
                    this.sudahAcc.loading = false
                    this.sudahAcc.items = res.data.data
                })
                .catch((err) => {
                    this.sudahAcc.loading = false
                    this.sudahAcc.items = []
                    console.log(err);
                })
        },
        showDetail(noPo, jenis) {
            this.kategori = "belum_acc"
            this.noPo = noPo
            this.jenis = jenis

            if (jenis.match(/USULAN BAYAR.*/)) {
                this.dialogDetailRekap = true
                this.loadHeaderRekap()
                this.loadDetailRekap1()
            } else {
                this.dialogDetailNonRekap = true
                this.loadHeaderNonRekap()
                this.loadDetailNonRekap1()
            }

        },
        showDetailSudahAcc(noPo, jenis) {
            this.kategori = "sudah_acc"
            this.noPo = noPo
            this.jenis = jenis

            if (jenis.match(/USULAN BAYAR.*/)) {

                this.dialogDetailRekap = true
                this.loadHeaderRekapSudah()
                this.loadDetailRekap1()
                console.log("Cek Usulan Bayar");
            } else {
                console.log("Cek Non Usulan Bayar");
                this.dialogDetailNonRekap = true
                this.loadHeaderNonRekap()
                this.loadDetailNonRekap1()
            }

        },
        showDetailRekap2(nama, index) {
            this.nama = nama
            this.dialogDetailRekap2 = true
            this.indexDetail2 = index
            this.itemsDetailRekap2.nama = this.detailRekap1.items[index].nama
            this.itemsDetailRekap2.mata_uang = this.detailRekap1.items[index].mata_uang
            this.itemsDetailRekap2.jumlah = this.detailRekap1.items[index].jumlah
            this.loadDetailRekap2()
            this.loadDetailRekap3()
        },
        showDetailSudahAccRekap2(nama) {
            this.nama = nama
            this.loadDetailRekap2()
            this.loadDetailRekap3()
        },
        async loadHeaderNonRekap() {
            this.header.loading = true
            await axios.post("/konfirmasi/nonrekap/header", {
                no_po: this.noPo,
                jenis: this.jenis
            })
                .then((res) => {
                    if (res.data.success == false) {
                        console.log(res.data.err);
                        return
                    }
                    this.$notify({ type: "success", text: res.data.message })
                    this.header.loading = false
                    this.header.items = res.data.data[0]
                })
                .catch((err) => {
                    this.header.loading = false
                    this.header.items = []
                    console.log(err);
                })
        },
        async loadDetailNonRekap1() {
            this.detailNonRekap1.loading = true
            this.detailNonRekap1.items = []
            await axios.post("/konfirmasi/nonrekap/detail", {
                no_po: this.noPo,
                jenis: this.jenis,
            })
                .then((res) => {
                    if (res.data.success == false) {
                        this.detailNonRekap1.loading = false
                        this.detailNonRekap1.items = []
                        console.log(res.data.err);
                        return
                    }

                    // this.$notify({ type: "success", text: res.data.message })
                    this.detailNonRekap1.loading = false
                    this.detailNonRekap1.items = res.data.data
                })
                .catch((err) => {
                    this.detailNonRekap1.loading = false
                    this.detailNonRekap1.items = []
                    console.log(err);
                })
        },
        async loadHeaderRekap() {
            this.headerRekap.loading = true
            await axios.post("/konfirmasi/rekap/header", {
                no_po: this.noPo,
                jenis: this.jenis
            })
                .then((res) => {
                    if (res.data.success == false) {
                        console.log(res.data.err);
                        return
                    }
                    this.$notify({ type: "success", text: res.data.message })
                    this.headerRekap.loading = false
                    this.headerRekap.items = res.data.data[0]
                })
                .catch((err) => {
                    this.headerRekap.loading = false
                    this.headerRekap.items = []
                    console.log(err);
                })
        },
        async loadHeaderRekapSudah() {
            this.headerRekap.loading = true
            await axios.post("/konfirmasi/rekap/headersudah", {
                no_po: this.noPo,
                jenis: this.jenis
            })
                .then((res) => {
                    if (res.data.success == false) {
                        console.log(res.data.err);
                        return
                    }
                    this.$notify({ type: "success", text: res.data.message })
                    this.headerRekap.loading = false
                    this.headerRekap.items = res.data.data[0]
                })
                .catch((err) => {
                    this.headerRekap.loading = false
                    this.headerRekap.items = []
                    console.log(err);
                })
        },
        async loadDetailRekap1() {
            this.detailRekap1.loading = true
            this.detailRekap1.items = []
            this.detailRekap2.items = []
            this.detailRekap3.items = []
            await axios.post("/konfirmasi/rekap/detail1", {
                no_po: this.noPo,
                jenis: this.jenis,
            })
                .then((res) => {
                    if (res.data.success == false) {
                        this.detailRekap1.loading = false
                        this.detailRekap1.items = []
                        console.log(res.data.err);
                        return
                    }

                    // this.$notify({ type: "success", text: res.data.message })
                    this.detailRekap1.loading = false
                    this.detailRekap1.items = res.data.data
                })
                .catch((err) => {
                    this.detailRekap1.loading = false
                    this.detailRekap1.items = []
                    console.log(err);
                })
        },
        async loadDetailRekap2() {
            this.detailRekap2.loading = true
            this.detailRekap2.items = []
            await axios.post("/konfirmasi/rekap/detail2", {
                no_po: this.noPo,
                jenis: this.jenis,
                nama: this.nama
            })
                .then((res) => {
                    if (res.data.success == false) {
                        this.detailRekap2.loading = false
                        this.detailRekap2.items = []
                        console.log(res.data.err);
                        return
                    }

                    // this.$notify({ type: "success", text: res.data.message })
                    this.detailRekap2.loading = false
                    this.detailRekap2.items = res.data.data
                })
                .catch((err) => {
                    this.detailRekap2.loading = false
                    this.detailRekap2.items = []
                    console.log(err);
                })
        },
        async loadDetailRekap3() {
            this.detailRekap3.loading = true
            this.detailRekap3.items = []
            await axios.post("/konfirmasi/rekap/detail3", {
                no_po: this.noPo,
                jenis: this.jenis,
                nama: this.nama
            })
                .then((res) => {
                    if (res.data.success == false) {
                        this.detailRekap3.loading = false
                        this.detailRekap3.items = []
                        console.log(res.data.err);
                        return
                    }

                    // this.$notify({ type: "success", text: res.data.message })
                    this.detailRekap3.loading = false
                    this.detailRekap3.items = res.data.data
                })
                .catch((err) => {
                    this.detailRekap3.loading = false
                    this.detailRekap3.items = []
                    console.log(err);
                })
        },
        getFormatNumber(rp) {
            return numeral(rp).format("0,2");
        },

        getFormatTanggal(tanggal) {
            moment.locale('id')
            if (moment(String(tanggal)).isValid()) {
                return moment(String(tanggal)).format('dddd, DD MMMM YYYY HH:mm')
            }
            return '-'
        },
    },
    computed: {

    }
}
</script>

<style>

</style>