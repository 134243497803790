<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="auto" lg="4" md="6" sm="12">
        <v-card>
          <v-card-title class="py-2" to="/konfirmasi">
            <div>
              <v-icon>mdi-cog</v-icon>Konfirmasi ACC 
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
              <v-row>
                <v-col cols="auto" lg="8" md="10" sm="12">
                  Menu untuk konfirmasi ACC/ Tidak ACC usulan/PO barang dan jasa. Klik "Selanjutnya" disamping untuk masuk ke menu tersebut.  
                </v-col>
                <v-col cols="auto" lg="4" md="2" sm="12">
                  <v-btn to="/konfirmasibarangjasa" color="success">Selanjutnya</v-btn>
                </v-col>
              </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {

};
</script>

<style>
</style>