import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Notifications from 'vue-notification'
// import VueToast from 'vue-toast-notification';
import axios from 'axios';
import '@mdi/font/css/materialdesignicons.css';
// import VueHtmlToPaper from "vue-html-to-paper";
import { setHeaderToken } from './utils/auth.js'
import JsonExcel from "vue-json-excel";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.component("DownloadExcel", JsonExcel);

// axios.defaults.baseURL = 'http://192.168.2.35:5001/api-konfirmasi3-dev/api/'
// axios.defaults.baseURL = 'http://192.168.2.35:5001/api-konfirmasi3/api/'
axios.defaults.baseURL = 'https://p9jo0gqhw1.execute-api.ap-southeast-1.amazonaws.com'

// axios.defaults.baseURL = 'http://192.168.2.35:5001/api-approval-dev/api/'
// import 'vue-toast-notification/dist/theme-sugar.css';
// import './registerServiceWorker'
// Vue.use(VueToast);
// Vue.use(VueHtmlToPaper, options);
const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

Vue.use(VueSweetalert2, options)
Vue.use(Notifications)

Vue.config.productionTip = false

// const options = {
//   name: "_blank",
//   specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
// };


const token = localStorage.getItem('token');
Vue.config.keyCodes = {
  shift: 20,
  backspace: 21,
}

if (token) {
  setHeaderToken(token)
}

store.dispatch('get_user', token)
  .then(() => {
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }).catch((error) => {
    console.error(error)
  })
