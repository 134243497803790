<template>
  <v-container>
    <!-- INPUT DATA -->
    <v-dialog v-model="dialogInputForm">
      <v-card>
        <v-card-title> INPUT DATA USER</v-card-title>
        <v-divider></v-divider>
        <v-form v-model="isValid">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  v-model="form.idnama"
                  :items="item.idnama"
                  :rules="rules.idnama"
                  label="ID Nama"
                ></v-select>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  label="Nama Customer"
                  :rules="rules.nama"
                  v-model="form.nama"
                />
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field
                  label="Alamat"
                  :rules="rules.alamat"
                  v-model="form.alamat"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="Person"
                  :rules="rules.person"
                  v-model="form.person"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="Telepon/HP"
                  :rules="rules.telepon"
                  v-model="form.telepon"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field label="Fax" v-model="form.fax" />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="Email"
                  :rules="rules.email"
                  v-model="form.email"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  v-model="form.sgroup"
                  :items="item.sgroup"
                  :rules="rules.sgroup"
                  label="Group Order"
                ></v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  v-model="form.kategori"
                  :items="item.kategori"
                  :rules="rules.kategori"
                  label="Kategori"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-col cols="12" md="3" >
              <v-btn v-if="!isEdit" color="success" @click="simpanData" :disabled="!isValid" class="mr-4"
                >Simpan</v-btn
              >
              <v-btn v-if="isEdit" color="warning" @click="editData" :disabled="!isValid" class="mr-4"
                >Update</v-btn
              >
              <v-btn color="grey" dark @click="resetData">Reset</v-btn>
            </v-col>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- END INPUT CUSTOMER -->
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      nama: "",
      email: "",
      password: "",
      errors: null,
    };
  },
  methods: {
    register: function () {
      let data = {
        name: this.namaLengkap,
        email: this.email,
        password: this.password,
      };
      this.$store
        .dispatch("register", data)
        .then((response) => {
          console.log(response);
          this.$router.push({
            name: "Login",
          });
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>